/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PublicTransportPricingZone } from '../models/public-transport-pricing-zone';

@Injectable({
  providedIn: 'root',
})
export class PublicTransportPricingZonesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation updatePublicTransportPricingZone
   */
  static readonly UpdatePublicTransportPricingZonePath = '/d/pt-pricing-zones/{publicTransportPricingZoneId}';

  /**
   * Update a public transport pricing zone.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatePublicTransportPricingZone()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePublicTransportPricingZone$Response(params: {
    publicTransportPricingZoneId: number;
    context?: HttpContext
    body: PublicTransportPricingZone
  }
): Observable<StrictHttpResponse<PublicTransportPricingZone>> {

    const rb = new RequestBuilder(this.rootUrl, PublicTransportPricingZonesService.UpdatePublicTransportPricingZonePath, 'put');
    if (params) {
      rb.path('publicTransportPricingZoneId', params.publicTransportPricingZoneId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PublicTransportPricingZone>;
      })
    );
  }

  /**
   * Update a public transport pricing zone.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updatePublicTransportPricingZone$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePublicTransportPricingZone(params: {
    publicTransportPricingZoneId: number;
    context?: HttpContext
    body: PublicTransportPricingZone
  }
): Observable<PublicTransportPricingZone> {

    return this.updatePublicTransportPricingZone$Response(params).pipe(
      map((r: StrictHttpResponse<PublicTransportPricingZone>) => r.body as PublicTransportPricingZone)
    );
  }

  /**
   * Path part for operation deletePublicTransportPricingZone
   */
  static readonly DeletePublicTransportPricingZonePath = '/d/pt-pricing-zones/{publicTransportPricingZoneId}';

  /**
   * Delete a public transport pricing zone.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePublicTransportPricingZone()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePublicTransportPricingZone$Response(params: {
    publicTransportPricingZoneId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PublicTransportPricingZonesService.DeletePublicTransportPricingZonePath, 'delete');
    if (params) {
      rb.path('publicTransportPricingZoneId', params.publicTransportPricingZoneId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Delete a public transport pricing zone.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deletePublicTransportPricingZone$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePublicTransportPricingZone(params: {
    publicTransportPricingZoneId: number;
    context?: HttpContext
  }
): Observable<void> {

    return this.deletePublicTransportPricingZone$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getPublicTransportPricingZones
   */
  static readonly GetPublicTransportPricingZonesPath = '/d/pt-pricing-zones';

  /**
   * Get all public transport pricing zones.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPublicTransportPricingZones()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublicTransportPricingZones$Response(params?: {
    countryCode?: string;
    query?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<PublicTransportPricingZone>>> {

    const rb = new RequestBuilder(this.rootUrl, PublicTransportPricingZonesService.GetPublicTransportPricingZonesPath, 'get');
    if (params) {
      rb.query('countryCode', params.countryCode, {});
      rb.query('query', params.query, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PublicTransportPricingZone>>;
      })
    );
  }

  /**
   * Get all public transport pricing zones.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPublicTransportPricingZones$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublicTransportPricingZones(params?: {
    countryCode?: string;
    query?: string;
    context?: HttpContext
  }
): Observable<Array<PublicTransportPricingZone>> {

    return this.getPublicTransportPricingZones$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PublicTransportPricingZone>>) => r.body as Array<PublicTransportPricingZone>)
    );
  }

  /**
   * Path part for operation createPublicTransportPricingZone
   */
  static readonly CreatePublicTransportPricingZonePath = '/d/pt-pricing-zones';

  /**
   * Create a new public transport pricing zone.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createPublicTransportPricingZone()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPublicTransportPricingZone$Response(params: {
    context?: HttpContext
    body: PublicTransportPricingZone
  }
): Observable<StrictHttpResponse<PublicTransportPricingZone>> {

    const rb = new RequestBuilder(this.rootUrl, PublicTransportPricingZonesService.CreatePublicTransportPricingZonePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PublicTransportPricingZone>;
      })
    );
  }

  /**
   * Create a new public transport pricing zone.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createPublicTransportPricingZone$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPublicTransportPricingZone(params: {
    context?: HttpContext
    body: PublicTransportPricingZone
  }
): Observable<PublicTransportPricingZone> {

    return this.createPublicTransportPricingZone$Response(params).pipe(
      map((r: StrictHttpResponse<PublicTransportPricingZone>) => r.body as PublicTransportPricingZone)
    );
  }

  /**
   * Path part for operation importDefaultZones1
   */
  static readonly ImportDefaultZones1Path = '/d/pt-pricing-zones/import-default-zones';

  /**
   * Import default public transport pricing zones.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `importDefaultZones1()` instead.
   *
   * This method doesn't expect any request body.
   */
  importDefaultZones1$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<PublicTransportPricingZone>>> {

    const rb = new RequestBuilder(this.rootUrl, PublicTransportPricingZonesService.ImportDefaultZones1Path, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PublicTransportPricingZone>>;
      })
    );
  }

  /**
   * Import default public transport pricing zones.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `importDefaultZones1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  importDefaultZones1(params?: {
    context?: HttpContext
  }
): Observable<Array<PublicTransportPricingZone>> {

    return this.importDefaultZones1$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PublicTransportPricingZone>>) => r.body as Array<PublicTransportPricingZone>)
    );
  }

  /**
   * Path part for operation getPublicTransportPricingZonesAsFeatureCollection
   */
  static readonly GetPublicTransportPricingZonesAsFeatureCollectionPath = '/d/pt-pricing-zones/feature-collection';

  /**
   * Get all public transport pricing zones as a GeoJSON feature collection.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPublicTransportPricingZonesAsFeatureCollection()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublicTransportPricingZonesAsFeatureCollection$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, PublicTransportPricingZonesService.GetPublicTransportPricingZonesAsFeatureCollectionPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Get all public transport pricing zones as a GeoJSON feature collection.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPublicTransportPricingZonesAsFeatureCollection$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublicTransportPricingZonesAsFeatureCollection(params?: {
    context?: HttpContext
  }
): Observable<string> {

    return this.getPublicTransportPricingZonesAsFeatureCollection$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

}
