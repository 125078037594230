/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AdminUserInfo } from '../models/admin-user-info';
import { SimpleUser } from '../models/simple-user';

@Injectable({
  providedIn: 'root',
})
export class UserService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation adminLogin
   */
  static readonly AdminLoginPath = '/api/v1/adminLogin';

  /**
   * Create a JWT token and login the current user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminLogin()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminLogin$Response(params?: {
    context?: HttpContext
    body?: SimpleUser
  }
): Observable<StrictHttpResponse<AdminUserInfo>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.AdminLoginPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AdminUserInfo>;
      })
    );
  }

  /**
   * Create a JWT token and login the current user.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminLogin$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminLogin(params?: {
    context?: HttpContext
    body?: SimpleUser
  }
): Observable<AdminUserInfo> {

    return this.adminLogin$Response(params).pipe(
      map((r: StrictHttpResponse<AdminUserInfo>) => r.body as AdminUserInfo)
    );
  }

  /**
   * Path part for operation adminUserInfo
   */
  static readonly AdminUserInfoPath = '/api/v1/adminUserinfo';

  /**
   * Get information about currently logged in admin user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminUserInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminUserInfo$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<AdminUserInfo>> {

    const rb = new RequestBuilder(this.rootUrl, UserService.AdminUserInfoPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AdminUserInfo>;
      })
    );
  }

  /**
   * Get information about currently logged in admin user.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adminUserInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminUserInfo(params?: {
    context?: HttpContext
  }
): Observable<AdminUserInfo> {

    return this.adminUserInfo$Response(params).pipe(
      map((r: StrictHttpResponse<AdminUserInfo>) => r.body as AdminUserInfo)
    );
  }

}
