/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CompanyLocationOwnerDto } from '../models/company-location-owner-dto';
import { FeatureFlag } from '../models/feature-flag';
import { LocationInfoDto } from '../models/location-info-dto';


/**
 * Internal API for creating and editing users
 */
@Injectable({
  providedIn: 'root',
})
export class UsersService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getUser
   */
  static readonly GetUserPath = '/d/users/{userId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUser$Response(params: {
    userId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<CompanyLocationOwnerDto>> {

    const rb = new RequestBuilder(this.rootUrl, UsersService.GetUserPath, 'get');
    if (params) {
      rb.path('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CompanyLocationOwnerDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUser(params: {
    userId: number;
    context?: HttpContext
  }
): Observable<CompanyLocationOwnerDto> {

    return this.getUser$Response(params).pipe(
      map((r: StrictHttpResponse<CompanyLocationOwnerDto>) => r.body as CompanyLocationOwnerDto)
    );
  }

  /**
   * Path part for operation updateUser
   */
  static readonly UpdateUserPath = '/d/users/{userId}';

  /**
   * Update a user.
   *
   * User locationMobilityProperties will stay the same as before, login as the user and use the regular user settings for this.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUser$Response(params: {
    userId: number;
    context?: HttpContext
    body: CompanyLocationOwnerDto
  }
): Observable<StrictHttpResponse<CompanyLocationOwnerDto>> {

    const rb = new RequestBuilder(this.rootUrl, UsersService.UpdateUserPath, 'put');
    if (params) {
      rb.path('userId', params.userId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CompanyLocationOwnerDto>;
      })
    );
  }

  /**
   * Update a user.
   *
   * User locationMobilityProperties will stay the same as before, login as the user and use the regular user settings for this.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUser(params: {
    userId: number;
    context?: HttpContext
    body: CompanyLocationOwnerDto
  }
): Observable<CompanyLocationOwnerDto> {

    return this.updateUser$Response(params).pipe(
      map((r: StrictHttpResponse<CompanyLocationOwnerDto>) => r.body as CompanyLocationOwnerDto)
    );
  }

  /**
   * Path part for operation deleteUser
   */
  static readonly DeleteUserPath = '/d/users/{userId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUser$Response(params: {
    userId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UsersService.DeleteUserPath, 'delete');
    if (params) {
      rb.path('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUser(params: {
    userId: number;
    context?: HttpContext
  }
): Observable<void> {

    return this.deleteUser$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getAllUsers
   */
  static readonly GetAllUsersPath = '/d/users';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllUsers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllUsers$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<CompanyLocationOwnerDto>>> {

    const rb = new RequestBuilder(this.rootUrl, UsersService.GetAllUsersPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CompanyLocationOwnerDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllUsers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllUsers(params?: {
    context?: HttpContext
  }
): Observable<Array<CompanyLocationOwnerDto>> {

    return this.getAllUsers$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CompanyLocationOwnerDto>>) => r.body as Array<CompanyLocationOwnerDto>)
    );
  }

  /**
   * Path part for operation createUser
   */
  static readonly CreateUserPath = '/d/users';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createUser$Response(params: {
    context?: HttpContext
    body: CompanyLocationOwnerDto
  }
): Observable<StrictHttpResponse<CompanyLocationOwnerDto>> {

    const rb = new RequestBuilder(this.rootUrl, UsersService.CreateUserPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CompanyLocationOwnerDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createUser(params: {
    context?: HttpContext
    body: CompanyLocationOwnerDto
  }
): Observable<CompanyLocationOwnerDto> {

    return this.createUser$Response(params).pipe(
      map((r: StrictHttpResponse<CompanyLocationOwnerDto>) => r.body as CompanyLocationOwnerDto)
    );
  }

  /**
   * Path part for operation enableFlags
   */
  static readonly EnableFlagsPath = '/d/users/{userId}/enableFlags';

  /**
   * Enable all (boolean) feature flags for the the given user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `enableFlags()` instead.
   *
   * This method doesn't expect any request body.
   */
  enableFlags$Response(params: {
    userId: number;

    /**
     * Which feature flags should be enabled: either &#x60;all&#x60; or the name of a feature flag.
     */
    flagsToEnable?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<FeatureFlag>>> {

    const rb = new RequestBuilder(this.rootUrl, UsersService.EnableFlagsPath, 'post');
    if (params) {
      rb.path('userId', params.userId, {});
      rb.query('flagsToEnable', params.flagsToEnable, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<FeatureFlag>>;
      })
    );
  }

  /**
   * Enable all (boolean) feature flags for the the given user.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `enableFlags$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  enableFlags(params: {
    userId: number;

    /**
     * Which feature flags should be enabled: either &#x60;all&#x60; or the name of a feature flag.
     */
    flagsToEnable?: string;
    context?: HttpContext
  }
): Observable<Array<FeatureFlag>> {

    return this.enableFlags$Response(params).pipe(
      map((r: StrictHttpResponse<Array<FeatureFlag>>) => r.body as Array<FeatureFlag>)
    );
  }

  /**
   * Path part for operation duplicateUser
   */
  static readonly DuplicateUserPath = '/d/users/{userId}/duplicate';

  /**
   * Duplicate the user and use the given email address for the new user - Careful! This can take a long time if `withCompanies=true`.
   *
   * This will duplicate the user, mobilityProperties and optimumModeEstimation feature flags, as well as all its companies if `wichCompanies=true`
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `duplicateUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  duplicateUser$Response(params: {
    userId: number;
    withCompanies?: boolean;
    newUserEmail: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<CompanyLocationOwnerDto>> {

    const rb = new RequestBuilder(this.rootUrl, UsersService.DuplicateUserPath, 'post');
    if (params) {
      rb.path('userId', params.userId, {});
      rb.query('withCompanies', params.withCompanies, {});
      rb.query('newUserEmail', params.newUserEmail, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CompanyLocationOwnerDto>;
      })
    );
  }

  /**
   * Duplicate the user and use the given email address for the new user - Careful! This can take a long time if `withCompanies=true`.
   *
   * This will duplicate the user, mobilityProperties and optimumModeEstimation feature flags, as well as all its companies if `wichCompanies=true`
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `duplicateUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  duplicateUser(params: {
    userId: number;
    withCompanies?: boolean;
    newUserEmail: string;
    context?: HttpContext
  }
): Observable<CompanyLocationOwnerDto> {

    return this.duplicateUser$Response(params).pipe(
      map((r: StrictHttpResponse<CompanyLocationOwnerDto>) => r.body as CompanyLocationOwnerDto)
    );
  }

  /**
   * Path part for operation getLocationInfos
   */
  static readonly GetLocationInfosPath = '/d/users/{userId}/locationInfos';

  /**
   * Get an overview of the users analysed locations.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLocationInfos()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLocationInfos$Response(params: {
    userId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<LocationInfoDto>>> {

    const rb = new RequestBuilder(this.rootUrl, UsersService.GetLocationInfosPath, 'get');
    if (params) {
      rb.path('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<LocationInfoDto>>;
      })
    );
  }

  /**
   * Get an overview of the users analysed locations.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getLocationInfos$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLocationInfos(params: {
    userId: number;
    context?: HttpContext
  }
): Observable<Array<LocationInfoDto>> {

    return this.getLocationInfos$Response(params).pipe(
      map((r: StrictHttpResponse<Array<LocationInfoDto>>) => r.body as Array<LocationInfoDto>)
    );
  }

}
